@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .b {
    border: 1px solid red;
  }

  .o {
    outline: 1px solid red;
  }

  .minor {
    @apply text-gray-600;
  }

  .xy {
    @apply flex justify-center items-center;
  }
}

body {
  @apply bg-gray-100 text-gray-900;
}

button,
input,
textarea {
  @apply outline-none;
}

input,
textarea {
  @apply bg-white;
}

button[disabled] {
  @apply cursor-default;
}

button.rect {
  @apply font-semibold rounded-lg px-4 py-2 text-xl;
}

button.rect.primary {
  @apply shadow text-white bg-blue-500;
}

.bg-tinted {
  @apply bg-black bg-opacity-30;
}

.menu-button {
  @apply block text-xl p-2 w-full text-left;
}

.note-card-button {
  @apply xy fill-current border-2 border-gray-300 minor text-lg rounded px-1;
}

.record-modal {
  @apply max-w-3xl w-full;
}
